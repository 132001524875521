class ImdbApi {
    api = "https://imdb.stream.labs.h3.se"

    async getInfo(imdbId) {

        for (let i = 0; i < 3; i++) {
            try {
                const url = `${this.api}/info/${imdbId}`;
                const res = await fetch(url);
                if (res.ok)
                    return await res.json();

                console.log(res.errored)

            } catch (error) {
                console.log(error);
            }
        }

        throw new Error('out of luck');
    }

    async getInfoOnSeason(imdbId, season) {
        const url = `${this.api}/info/${imdbId}/${season}`;
        const res = await fetch(url);
        if (res.ok)
            return await res.json();

        throw new Error(res.error);
    }

    async getInfoOnEpisode(imdbId, season, episode) {
        const url = `${this.api}/info/${imdbId}/${season}/${episode}`;
        const res = await fetch(url);
        if (res.ok)
            return await res.json();

        throw new Error(res.error);
    }


    async searchTitle(title) {
        const url = `${this.api}/search?title=${title}`;
        const res = await fetch(url);
        if (res.ok)
            return await res.json();

        throw new Error(res.error);
    }
}

const imdbApi = new ImdbApi();
export default imdbApi;
