<script>
import axios from "axios";

const config = {
  default: {
    env: {
      imdbApi: "https://imdb.stream.labs.h3.se",
    }
  }
};

async function searchTitle(query) {
  const url = `${config.default.env.imdbApi}/search?title=${query}`;
  const res = await axios.get(url);

  if (res.data.Response === 'True')
    return [res.data];

  return []
}

export default {
  data() {
    return {
      query: this.$route.query.query,
      result: []
    };
  },
  methods: {
    view(id) {
      console.log(id);
      this.$router.push({name: 'AssetView', query: {id: id}});
    },
    search() {
      console.log("ZZZZZ")
      searchTitle(this.query).then(result => {
        this.result = result;
      });
    },
  },
  beforeMount() {
    console.log('beforeMount: Component is about to be mounted to the DOM');
    searchTitle(this.query).then(result => {
      this.result = result;
    });
  },
};
</script>

<template>
  <div>
    <header>
      <div class="search_result container">
        <h2>Search result</h2>
      </div>

      <div class="header_search_mini container">
        <div class="header_search_image">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g clip-path="url(#clip0_1253_348)">
              <path
                  d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"/>
            </g>
          </svg>

        </div>
        <div class="header_search_input_mini">
          <input v-model="query" @keyup.enter="search" placeholder="Search...">
        </div>
        <ul class="options">
        </ul>
      </div>
    </header>

    <section class="wrapper search_result_previews container">
      <div v-for="item in result" :key="item.id" @click="view(item.imdbID)">
        <div class="preview_item">
          <img :src="item.Poster">
          <h2>{{ item.Title }}</h2>
          <h3>{{ item.Year }}</h3>
        </div>
      </div>
    </section>
  </div>
</template>

<style>

body {
  padding: 0;
  margin: 0;
  background-color: #000000;
  color: #FFFFFFFF;
  font-family: "Sedan SC", sans-serif;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

/*Search results*/

.search_result {
  margin: 0 auto;
}

.search_result > h2 {
  color: #ffffff;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
}

.header_search_mini {
  display: flex;
  align-items: center;
  padding: 6px 0;
  fill: #646464;
  border-bottom: 1px solid #646464;
}

.header_search_mini:focus-within {
  fill: #00B7FF;
  border-bottom: 1px solid #00B7FF;
  transition: 0.5s;
}

.header_search_image {
  margin: 8px;
}

.header_search_input_mini > input {
  flex-shrink: 0;
  height: 40px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
  fill: #646464;
}

/*search results wrapper*/

.search_result_previews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 45px;
  margin: 80px auto;
}

.preview_item {
  transition: transform 0.3s ease;
}

.preview_item:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.preview_item > img {
  width: 225px;
  height: 315px;
  border-radius: 20px;
  border: 1px solid #232121;
}

.preview_item > h2 {
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.preview_item > h3 {
  color: #646464;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}
</style>
