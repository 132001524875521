import {Relay} from "nostr-tools/relay";
import {finalizeEvent, generateSecretKey, getPublicKey} from "nostr-tools/pure";

function getAssetShotId(asset) {
    return `${asset.identifier.type}:${asset.identifier.id}`
}

class NostrmClient {

    constructor() {
    }

    connect(url) {
        return new Promise((resolve) => {
            Relay.connect(url).then((relay) => {
                console.log(`relay connected to ${url}`);
                this.relay = relay

                // Do auto-reconnect (TODO: verify that this works)
                this.relay.onclose = (reason) => {
                    console.log(`relay closed ${reason}`)

                    this.connect(url).then()
                }
                resolve()
            })

        })
    }

    generateKeys() {
        const sk = generateSecretKey()

        this.keys = {
            sk,
            pk: getPublicKey(sk),
        }
    }

    publish(asset) {

        let event = finalizeEvent({
            kind: 6666,
            created_at: Math.floor(Date.now() / 1000),
            tags: [
                ["i", getAssetShotId(asset)],
                ['c', asset.container.format],
                ['v', asset.video.codec],
                ['A', asset.audio.codec],
            ],
            content: JSON.stringify(asset),
        }, this.keys.sk)

        this.relay.publish(event)
    }

    verifyExist(asset) {
        return new Promise((resolve) => {
            this.getAssetList(getAssetShotId(asset)).then((list) => {
                resolve(list.some((candidate) => candidate.infoHash === asset.infoHash))
            })
        })
    }

    getAssetList(id) {
        return new Promise((resolve) => {
            let list;
            list = []

            const sub = this.relay.subscribe([
                {
                    kinds: [6666],
                    "#i": [id],
                },
            ], {
                onevent(event) {
                    const content = JSON.parse(event.content)
                    list.push(content)
                },
                oneose() {
                    sub.close()
                    resolve(list)
                }
            })
        })
    }

    publishIfNotExist(asset) {
        this.verifyExist(asset).then(exists => {
            if (exists) return

            this.publish(asset)
        })
    }
}

export {NostrmClient, getAssetShotId}
