<script>
import WebTorrent from 'webtorrent/dist/webtorrent.min'
import SimplePeer from 'simple-peer'
import imdbApi from "@/ImdbApi";
import SeasonMagic from "@/components/SeasonMagic.vue";
import {getAssetShotId, NostrmClient} from "@/nostrm-client";

const rtcConfig = {
  iceServers: [
    {
      urls: [
        "turn:turn.stream.labs.h3.se",
      ],
      username: "test",
      credential: "testme",
    },
    {
      urls:
          ["stun:stun.stream.labs.h3.se"],
      username: "test",
      credential: "testme",
    }],
  iceTransportPolicy: "all",
  iceCandidatePoolSize: 0,
}


// Old syntax
// const WEBTORRENT_ANNOUNCE = [
//   ['wss://tracker.webtorrent.dev']
// ]
//
// let options = {
//   announce: WEBTORRENT_ANNOUNCE,
//   maxWebConns: 500,
// }

// let options = {
//   // announce: ['wss://tracker.webtorrent.dev', 'wss://tracker.openwebtorrent.com'],
//   announce: ['wss://tracker.stream.labs.h3.se'],
//   maxWebConns: 500,
// }

// const config = {
//   default: {
//     env: {
//       bcqApi: "https://bcq.stream.labs.h3.se",
//     }
//   }
// };

const wt = new WebTorrent({
  tracker: {
    rtcConfig: {
      ...SimplePeer.config,
      ...rtcConfig
    }
  }
});

wt.on('warning', function (err) {
  console.error(err)
})
wt.on('error', function (err) {
  console.error(err)
})

/**
 * Make sure you run this over and HTTPS connection
 */
navigator.serviceWorker.register('./sw.min.js', {scope: './'}).then(reg => {
  console.log("I am service worker!")
  console.log(reg)

  const worker = reg.active || reg.waiting || reg.installing

  function checkState(worker) {
    console.log("Check state")
    console.log(worker.state)

    if (worker.state !== 'activated') return false

    console.log("state is activated")

    const result = wt.createServer({controller: reg})

    console.log("The result from creating the server" + result)
    console.log(result.registration.scope)

    return result
  }

  if (!checkState(worker)) {
    worker.addEventListener('statechange', ({target}) => checkState(target))
  }
})

const nc = new NostrmClient()
// await nc.connect('ws://s05.labs.h3.se:7777')
await nc.connect('wss://relay.stream.labs.h3.se')

async function searchAssets(imdbId) {
  const asset = {identifier: {type: 'imdbid', id: imdbId}}
  const x = await nc.getAssetList(getAssetShotId(asset))
  return [x]

  // const url = `${config.default.env.bcqApi}/blocksearch/infoHashAnnotation?imdbId=${imdbId}`;
  // const res = await axios.get(url);
  // return [res.data];
}


export default {
  components: {SeasonMagic},
  data() {
    return {
      id: this.$route.query.id,
      mainAsset: {Title: '', Plot: ''},
      selectedId: undefined,
      selectedViewingAsset: undefined,
      viewingAssets: [],
      // peers: 0,
      // downloaded: 0,
      progress: 0,
      // torrent: {infoHash: '', peers: 0, downloaded: 0, progress: 0},
      torrent: undefined,
      wt: undefined
    };
  },
  methods: {
    async handleSelectEpisodeUpdate(id) {
      // THIS IS VERY VERY VERY UGGLY!
      console.log("Zoool2");
      console.log(id);
      this.activeAssetId = id

      //select first episode of first season to view
      // const res = await searchAssets(this.activeAssetId.substring(2, 10))
      const res = await searchAssets(this.activeAssetId)

      this.viewingAssets = res[0];
      this.selectedViewingAsset = this.viewingAssets.length > 0 ? 0 : undefined;
      console.log(res)

      if (this.selectedViewingAsset !== undefined)
        this.download(this.viewingAssets[this.selectedViewingAsset].magnetURI)
    },
    next() {
      console.log("next");
      if (this.selectedViewingAsset === undefined) {
        return
      }

      wt.remove(this.viewingAssets[this.selectedViewingAsset].magnetURI)
      this.selectedViewingAsset = (this.selectedViewingAsset + 1) % this.viewingAssets.length;
      console.log(this.selectedViewingAsset);
      this.download(this.viewingAssets[this.selectedViewingAsset].magnetURI)
    },

    load() {
      console.log('Ready to play!')

      // Torrents can contain many files. Let's use the .mp4 file
      const file = this.torrent.files.find(file => file.name.endsWith('.mp4'))

      console.log(file.streamURL)
      const player = document.querySelector('video')
      // player.src = file.streamURL
      file.streamTo(player)
    },
    download(magnetURI) {
      console.log("Download " + magnetURI);

      // const torrent = wt.add(hash, options);
      wt.add(magnetURI, (torrent) => {
        this.torrent = torrent

        this.load()

        // torrent.on('ready', () => {
        // })

        torrent.on('warning', wrn => {
          console.log(wrn)
        });
        torrent.on('error', err => {
          console.log(err)
        });
        torrent.on('wire', wire => {
          console.log("number of peers: " + torrent.numPeers)
          console.log(`new peer connected from: ${wire.remoteAddress}:${wire.remotePort}`)
        });
        torrent.on('download', () => {
          this.progress = this.torrent.progress
        });
        torrent.on('upload', () => {
        });
      });
    }
  },
  beforeCreate() {
    for(let torrent of wt.torrents) {
      console.log(torrent.infoHash)
    }
  },
  async created() {
    this.mainAsset = await imdbApi.getInfo(this.id);

    if (this.mainAsset.Type === 'series') {
      this.mainAsset.seasonInfo = [];

      console.log(this.mainAsset);
      for (let i = 1; i <= this.mainAsset.totalSeasons; ++i) {
        const zoo = await imdbApi.getInfoOnSeason(this.mainAsset.imdbID, i)
        console.log(zoo)
        this.mainAsset.seasonInfo.push(zoo)
      }

      this.handleSelectEpisodeUpdate(this.mainAsset.seasonInfo[0].Episodes[0].imdbID)
    } else {
      this.handleSelectEpisodeUpdate(this.mainAsset.imdbID)
    }
  },
  mounted() {
    console.log('mounted: Component has been mounted to the DOM');
  },
  unmounted() {
    console.log('destroyed: Component has been destroyed');

    if (this.viewingAssets !== undefined) {
      wt.remove(this.viewingAssets[this.selectedViewingAsset].infoHash)
      this.viewingAssets = undefined;
    }
  }
}
</script>

<template>
  <div>
    <section class="main container">

      <div class="mediaplayer_wrapper">
        <div class="mediaplayer">
          <video id="video-container" width="640" height="360" controls>
          </video>

          <div v-if="torrent !== undefined">
            hash: {{ torrent.infoHash }}
            progress: {{ progress * 100 }}%
            <button @click="next">Next</button>
          </div>
          <div v-else>
            waiting for torrent
          </div>
        </div>

        <div class="mediaplayer_content">
          <h2>{{ this.mainAsset.Title }}</h2>
          <p>{{ this.mainAsset.Plot }}</p>
          <div class="mediaplayer_buttons">
            <button class="button_trailer"><img src="img/icons8-play-48.png" @click="load">
              <p>Trailer</p></button>
            <button class="mediaplayer_button button_like"><img src="img/icons8-like-24.png"></button>
            <button class="mediaplayer_button button_share"><img src="img/icons8-share-50.png"></button>
            <div class="mediaplayer_button rating"><p>7,8</p></div>
          </div>
        </div>
      </div>

      <SeasonMagic v-if="mainAsset.Type === 'series'" :mainAsset="this.mainAsset"
                   @select-episode="handleSelectEpisodeUpdate">
      </SeasonMagic>
    </section>
  </div>
</template>

<style>


.main {
  margin-top: 70px;
}

.mediaplayer_wrapper {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.mediaplayer_content {
  display: flex;
  flex-direction: column;
}

.mediaplayer_content > h2 {
  font-size: 33px;
  font-weight: 300;
  margin-top: 0;
}

.mediaplayer_content > p {
  font-size: 16px;
  text-align: justify;
  font-weight: 200;
  margin-bottom: 50px;
}

.mediaplayer_buttons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.mediaplayer_button {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: none;
}

.mediaplayer_button > img {
  width: 20px;
  height: 25px;
  align-self: center;
  object-fit: contain;
}

.button_trailer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #646464;
  gap: 10px;
}

.button_trailer > img {
  width: 25px;
  height: 25px;
}

.button_trailer > p {
  color: #ffffff;
  font-size: 16px;
}

.button_like {
  background-color: #fc3a56;
}

.button_share {
  background-color: #3a9bfc;
}

.button_share > img, .button_like > img {
  width: 25px;
  height: 30px;
}

.rating {
  background-color: #32b231;
  text-align: center;
}

.rating > p {
  font-size: 24px;
  margin: 3px auto;
}


.seasons_container {
  margin: 80px auto;
  padding: 20px;
  background-color: inherit;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tabs {
  display: flex;
  margin-bottom: 20px;

}

.tab-button {
  padding: 10px 20px 10px 0;
  border: none;
  border-radius: 5px;
  background-color: inherit;
  color: #646464;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: 0.2s;
}

.tab-button.active {
  color: #ffffff;
}

.tab-button:hover {
  color: #ffffff;
}

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;

}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 200%; /* Увеличим ширину контейнера для размещения 10 изображений */
}

.slide {
  min-width: 20%; /* Каждое изображение занимает 20% от ширины слайдера */
  box-sizing: border-box;
  height: 400px;
}

.slide img {
  width: 90%;
  display: block;
  border-radius: 10px;
}

.prev, .next {
  position: absolute;
  top: 35%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transform: translateY(-50%);
  z-index: 100;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.series_desc {
  font-size: 18px;
}

.season_num {
  font-size: 16px;
}
</style>
