<script>
import imdbApi from "@/ImdbApi";

export default {
  data() {
    return {
      info: {
        Poster: undefined,
        Title: '',
      }
    };
  },
  props: {
    imdbId: {
      type: String
    },
  },
  methods: {
    selectEpisode() {
      console.log(this.imdbId);
    }
  },
  async beforeCreate() {
    console.log('beforeCreate: Component has been created');
    console.log(this.imdbId)
    this.info = await imdbApi.getInfo(this.imdbId)
  }
}
</script>

<template>
  <div class="slide">
    <img :src="info.Poster" alt="Slide 1" @click="$emit('selectEpisode', this.imdbId)">
    <h2 class="series_desc">{{ info.Title }}</h2>
    <p class="season_num">{{info.Episode}}</p>
  </div>
</template>

<style >

.slide {
  width: 350px;
  height: 300px;

  min-width: 20%; /* Каждое изображение занимает 20% от ширины слайдера */
  box-sizing: border-box;
}

.slide > img {
  width: 350px;
  height: 200px;
  display: block;
  border-radius: 10px;
}

.prev, .next {
  position: absolute;
  top: 35%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transform: translateY(-50%);
  z-index: 100;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.series_desc {
  font-size: 18px;
}

.season_num {
  font-size: 16px;
}

</style>
