<script>
export default {
  data() {
    return {
      query: 'sex and the city',
    };
  },
  methods: {
    goToAbout() {
      this.$router.push('/about');  // Navigate to the About page
    },
    search() {
      console.log(this.query);
      this.$router.push({name: 'SearchResult', query: {query: this.query}});
    },
  },
};
</script>

<template>
  <div class="container header_search_wrapper">
    <div class="logo">
      <img src="../assets/player.png" alt="" class="logo_img">
      <div class="logo_title"><p>THE OPEN STREAM</p></div>
    </div>
    <div class="header_search">
      <div class="header_search_image">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
          <g clip-path="url(#clip0_1253_348)">
            <path
                d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"/>
          </g>
        </svg>
      </div>

      <div class="header_search_input">
        <input v-model="query" @keyup.enter="search" placeholder="Search...">
      </div>
    </div>
  </div>
</template>

<style >
.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.header_search_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 300px auto 0 auto;
  gap: 80px;
}

.logo {
  width: 570px;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.logo_img {
  filter: brightness(0) invert(1);
  width: 120px;
  height: 110px;
}

.logo_title {
  font-size: 40px;
  width: 355px;
  height: 75px;
  line-height: 55px;
  position: relative;
}

.logo_title:before {
  content: " ";
  background: #ffffff;
  width: 2px;
  height: 100px;
  /* линия смещена на 10px справа от текста*/
  top: 5px;
  left: -45px;
  /* включаем абсолютное позиционирование относительно блока с relative */
  position: absolute;
}

.logo_title > p {
  margin: 0;
  font-family: "Days One", sans-serif;
}


.header_search {
  display: flex;
  align-items: center;
  width: 790px;
  height: 60px;
  background-color: inherit;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 5px 10px 2px rgba(255, 255, 255, 0.2);
  font-size: 20px;
}

.header_search:focus-within {
  fill: #00b7ffff;
  box-shadow: 0px 5px 10px 2px rgb(0, 183, 255, 0.2);
  transition: 0.5s;
}

.header_search_image {
  margin: 8px 15px 8px 20px;
}


.header_search_input > input {
  flex-shrink: 0;
  width: 790px;
  height: 60px;
  font-size: 22px;
  border: none;
  background-color: transparent;
  color: inherit;
  outline: none;
}

@media screen and (max-width: 850px) {
  .logo {
    width: 380px;
    height: 90px;
  }

  .logo_img {
    width: 80px;
    height: 70px;
  }

  .logo_title {
    font-size: 30px;
    width: 255px;
    height: 65px;
    line-height: 35px;
  }

  .logo_title:before {
    height: 70px;
    top: 5px;
    left: -20px;
  }

  .header_search {
    width: 490px;
    height: 60px;
    font-size: 16px;
  }

  .header_search_input > input {
    width: 490px;
    height: 60px;
    font-size: 16px;
  }
}

@media screen and (max-width: 520px) {
  .logo {
    width: 250px;
    height: 70px;
  }

  .logo_img {
    width: 60px;
    height: 50px;
  }

  .logo_title {
    font-size: 18px;
    width: 155px;
    height: 35px;
    line-height: 25px;
  }

  .logo_title:before {
    height: 40px;
    top: 5px;
    left: -20px;
  }

  .header_search {
    width: 350px;
    height: 60px;
    font-size: 13px;
  }

  .header_search_input > input {
    width: 350px;
    height: 60px;
    font-size: 13px;
  }
}
</style>
