import {createRouter, createWebHistory} from 'vue-router';
import SearchPage from '@/views/SearchPage.vue'
// import SearchResultPage from "@/views/SearchResultPage.vue";
// import AssetViewPage from "@/views/AssetViewPage.vue";
import ResultPage from "@/views/ResultPage.vue";
import AssetViewPage2 from "@/views/AssetViewPage2.vue";

const routes = [
    {
        path: '/',
        name: 'Search',
        component: SearchPage,
    },
    {
        path: '/result',
        name: 'SearchResult',
        component: ResultPage,
    },
    // {
    //     path: '/result',
    //     name: 'SearchResult',
    //     component: SearchResultPage,
    // },
    {
        path: '/view',
        name: 'AssetView',
        component: AssetViewPage2,
        // component: AssetViewPage,
    },
    // {
    //     path: '/view',
    //     name: 'AssetView',
    //     component: AssetViewPage,
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;