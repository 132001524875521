<script>

import EpisodeIcon from "@/components/EpisodeIcon.vue";

// const slides = document.querySelector('.slides');
// const slideWidth = document.querySelector('.slide').clientWidth;
// const totalSlides = document.querySelectorAll('.slide').length;
// let currentIndex = 0;

// Функция для переключения слайдов
// function updateSlides() {
//   slides.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
// }

// // Обработчик клика на кнопку "Следующий"
// document.querySelector('.next').addEventListener('click', () => {
//   currentIndex = (currentIndex + 1) % (totalSlides - 1); // Учитываем, что показываем только 5 изображений
//   updateSlides();
// });
//
// // Обработчик клика на кнопку "Предыдущий"
// document.querySelector('.prev').addEventListener('click', () => {
//   currentIndex = (currentIndex - 1 + (totalSlides - 1)) % (totalSlides - 1); // Учитываем, что показываем только 5 изображений
//   updateSlides();
// });
//
// // Инициализация
// updateSlides();


function clamp(val, min, max) {
  return val > max ? max : val < min ? min : val;
}

export default {
  components: {EpisodeIcon},
  data() {
    return {
      slider: 0,
      activeSeason: 1,
    };
  },
  props: {
    mainAsset: {
      type: Object
    },
  },
  methods: {
    onLeftClick(x) {
      console.log(x)
      const slideWidth = document.querySelector('.slide').clientWidth;
      const slides = document.querySelector('.slides');
//      slides.style.transform = `translateX(-${currentIndex * slideWidth}px)`;
      const max = this.mainAsset.seasonInfo[this.activeSeason-1].Episodes.length -1
      this.slider = clamp(this.slider + x, 0, max)
      slides.style.transform = `translateX(-${this.slider * slideWidth}px)`;
      // currentIndex = (currentIndex - 1 + (totalSlides - 1)) % (totalSlides - 1); // Учитываем, что показываем только 5 изображений
      // updateSlides()
    },
    handleMessageUpdate(id) {
      console.log("Zoool");
      console.log(id);
      this.$emit('selectEpisode', id);
    }
  },
  // Lifecycle Methods
  async beforeCreate() {
    console.log('beforeCreate: Component is being initialized');
  },
  created() {
    console.log('created: Component has been created');
    console.log(this.mainAsset)
    console.log('updated: Component has been updated');
  },
  beforeMount() {
    console.log('beforeMount: Component is about to be mounted to the DOM');
  },
  mounted() {
    console.log('mounted: Component has been mounted to the DOM');
    console.log(this.mainAsset)
  },
  beforeUpdate() {
    console.log('beforeUpdate: Component data is about to be updated');
  },
  updated() {
    console.log('updated: Component data has been updated');
  },
  beforeUnmount() {
    console.log('beforeDestroy: Component is about to be destroyed');
  },
  unmounted() {
    console.log('destroyed: Component has been destroyed');
  },
};

</script>

<template>
  <div class="seasons_container">
    <div class="seasons title">
      <h2>Seasons and episodes</h2>
    </div>
    <div class="tabs">
      <div v-for="i in parseInt(this.mainAsset.totalSeasons)" :key="i">
        <button class="tab-button active" @click="this.activeSeason=i" data-season="season1">Season {{ i }}</button>
      </div>
    </div>
    <div class="slider">
      <div v-if="this.mainAsset.seasonInfo.length >0">
        <div class="slides">
          <div v-for="(episode) in this.mainAsset.seasonInfo[this.activeSeason-1].Episodes"
               :key="episode.imdbID">
            <EpisodeIcon :imdbId="episode.imdbID" @select-episode="handleMessageUpdate"></EpisodeIcon>
          </div>
        </div>
      </div>
      <button class="prev" @click="onLeftClick(-1)">&#10094;</button>
      <button class="next" @click="onLeftClick(1)">&#10095;</button>
    </div>
  </div>
</template>

<style>

.seasons_container {
  margin: 80px auto;
  padding: 20px;
  background-color: inherit;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.seasons > h2 {
  text-align: left;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px 10px 0;
  border: none;
  border-radius: 5px;
  background-color: inherit;
  color: #646464;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  transition: 0.2s;
}

.tab-button.active {
  color: #ffffff;
}

.tab-button:hover {
  color: #ffffff;
}

.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
}

.slide > h2, .slide > p {
  text-align: left;
}

.prev, .next {
  position: absolute;
  top: 35%;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  transform: translateY(-50%);
  z-index: 100;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.series_desc {
  font-size: 18px;
}

.season_num {
  font-size: 16px;
}
</style>
